import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useDrop } from "react-dnd";
import {
  RootOrderTypeModel,
  RootVehicleModel,
} from "../../../../application/repositories/assignment-of-vehicles-repository";
import { useAssignmentOfVehicles } from "../../state-management/assignment-of-vehicles-hook";
import { AffectedOrdersList } from "./affected-orders-list";
import { format, isAfter } from "date-fns";
import { filter } from "lodash";

export const DropOrderContainer: FC<{
  vehicleContainer: RootVehicleModel;
  noAffectedPaxInTri: number;
  type: RootOrderTypeModel;
}> = ({ vehicleContainer, noAffectedPaxInTri, type }): JSX.Element => {
  const { removeAffectedVehicle, removeOrderFromVehicle } =
    useAssignmentOfVehicles();
  // const isCompleted =
  //   vehicleContainer.pax.total === vehicleContainer.vehicleType.capacity ||
  //   noAffectedPaxInTri === 0;

  const isCompleted = false;

  const percentProgress =
    (vehicleContainer.pax.total * 100) / vehicleContainer.vehicleType.capacity;

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: isCompleted ? "Completed" : vehicleContainer.trip,
      drop: () => ({ ...vehicleContainer }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [vehicleContainer, isCompleted]
  );

  const isActive = canDrop && isOver;
  let backgroundColor = "#eee";
  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }

  const departureTimes = (vehicleContainer.orders ?? [])
    .map((ord) => ord.departureTime)
    .filter((time) => time !== undefined)
    .sort((a, b) => {
      const _isAfter = isAfter(new Date(String(a)), new Date(String(b)));
      return _isAfter ? -1 : 1;
    });

  const arrivingTimes = (vehicleContainer.orders ?? [])
    .map((ord) => ord.arrivingTime)
    .filter((time) => time !== undefined)
    .sort((a, b) => {
      const _isAfter = isAfter(new Date(String(a)), new Date(String(b)));
      return _isAfter ? -1 : 1;
    });

  const strDepartureTime =
    departureTimes.length > 0
      ? format(new Date(departureTimes[0]!), "dd/MM/yyyy HH:mm")
      : "";
  const strArrivingTime =
    arrivingTimes.length > 0
      ? format(new Date(arrivingTimes[0]!), "dd/MM/yyyy HH:mm")
      : "";

  return (
    <Box
      ref={drop}
      sx={{
        "&:not(:last-child)": {
          borderBottom: "solid 1px #ddd",
        },
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack direction={"row"} spacing={2} alignItems="center">
          <Box sx={{ width: 100 }}>
            <img
              src={
                type === RootOrderTypeModel.N
                  ? "https://bo.ozeroute.com/assets/images/shuttel.png"
                  : "https://cdn.ozeroute.com/b/assets/images/standard_vehicle.png"
              }
              alt="car"
              width={100}
            />
          </Box>
          <Box>
            <Stack direction={"row"} spacing={1}>
              <Typography>
                {" "}
                {vehicleContainer.id_str
                  ? vehicleContainer.id_str
                  : vehicleContainer.vehicleType.name}
              </Typography>
              <Typography sx={{ fontSize: 12 }}>
                {`${strDepartureTime} - ${strArrivingTime} `}
              </Typography>
            </Stack>
            <Typography>
              {" "}
              Capacité : <b>{vehicleContainer.vehicleType.capacity}</b>
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            size="small"
            color="error"
            onClick={() => removeAffectedVehicle(vehicleContainer)}
          >
            <DeleteOutlineIcon fontSize="small" />
          </IconButton>
        </Stack>

        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress
              variant="determinate"
              color={isCompleted ? "secondary" : "primary"}
              value={percentProgress}
            />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="caption" color="text.secondary">
              {vehicleContainer.pax.total} /{" "}
              {vehicleContainer.vehicleType.capacity}
            </Typography>
          </Box>
        </Box>

        {vehicleContainer.orders.length > 0 && (
          <AffectedOrdersList
            orders={vehicleContainer.orders}
            handleRemove={(item) =>
              removeOrderFromVehicle(vehicleContainer, item)
            }
          />
        )}
        <Box
          sx={{
            mt: 1,
            borderStyle: "dashed solid",
            borderWidth: isCompleted ? 0 : 1,
            borderColor: "#ddd",
            borderRadius: 1,
            height: isCompleted ? 0 : 90,
            width: "100%",
            color: "black",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor,
          }}
        >
          Faites glisser une commande ici
        </Box>
      </Paper>
    </Box>
  );
};
