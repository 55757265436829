import { Backdrop, BackdropProps, CircularProgress } from "@mui/material";
import { FC, useEffect } from "react";

export const BlurBackdrop: FC<BackdropProps & { onClose?: () => void }> = ({
  onClose,
  ...props
}): JSX.Element => {
  useEffect(() => {
    if (props.open && onClose !== undefined) {
      const timer = setTimeout(() => {
        onClose();
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [props]);
  return (
    <Backdrop
      sx={{
        color: "black",
        top: 0,
        left: 0,
        position: "absolute",
        width: "auto",
        height: "auto",
        zIndex: 99999,
        backdropFilter: "blur(1px)",
        backgroundColor: "rgba(58, 144, 229, 0.3)",
        p: 0,
      }}
      onClick={() => {}}
      {...props}
    >
      <CircularProgress color="success" variant="indeterminate" size={44} />
    </Backdrop>
  );
};
