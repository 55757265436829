import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useDrag } from "react-dnd";
import {
  OrderModel,
  RootOrderModel,
  RootVehicleModel,
} from "../../../../application/repositories/assignment-of-vehicles-repository";
import { useAssignmentOfVehicles } from "../../state-management/assignment-of-vehicles-hook";
import { OrderPaxForm } from "./order-pax-form";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const OrderCard: React.FC<{ order: RootOrderModel }> = ({
  order,
}): JSX.Element => {
  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const totalPax = React.useMemo(
    () =>
      order.orders.reduce((acc, curr) => {
        return acc + curr.pax.total;
      }, 0),
    [order]
  );

  return (
    <>
      <Card
        elevation={0}
        sx={{
          minWidth: 345,
          width: "100%",
          cursor: "pointer",
        }}
      >
        <CardHeader
          title={`${order.orders.length} commande${
            order.orders.length > 1 ? "s" : ""
          }`}
          subheader={
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <Typography>
                {order.orders[0].type === "N"
                  ? "Navette et bus"
                  : "Transfert Privé"}
              </Typography>
              <Typography color="secondary">{totalPax} passagers</Typography>
            </Stack>
          }
          action={
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Stack direction={"column"} spacing={2}>
              {order.orders.map((item, index) => (
                <OrderItemComponent
                  key={index}
                  order={item}
                  trip={order.trip}
                />
              ))}
            </Stack>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

const OrderItemComponent: React.FC<{ order: OrderModel; trip: string }> = ({
  order,
  trip,
}): JSX.Element => {
  const {
    getTotalReservedInVehicle,
    getAffectedPaxOrder,
    addPartialOrderToVehicle,
    getAvailablePlace,
    //checkCapacityVehicle,
  } = useAssignmentOfVehicles();
  const [state, setState] = React.useState<{
    nbrPalaceRestante: number;
    openDialog: boolean;
    targetVehicle: RootVehicleModel | undefined;
  }>({
    nbrPalaceRestante: 0,
    openDialog: false,
    targetVehicle: undefined,
  });

  const handleCloseDialog = () => {
    setState((state) => ({
      ...state,
      nbrPalaceRestante: 0,
      openDialog: false,
      targetVehicle: undefined,
    }));
  };

  const paxAffected = getAffectedPaxOrder(order, trip);
  const paxNoAffected = order.pax.total - paxAffected;
  const isAllPaxAffected =
    paxAffected === order.pax.total || paxNoAffected === 0;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: isAllPaxAffected ? "NO" : trip,
      item: { ...order },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<RootVehicleModel>();
        if (item && dropResult) {
          //  const canAccept = checkCapacityVehicle(dropResult, order);

          const reservedInVehicle = getTotalReservedInVehicle(dropResult);
          const availableInVehicle = getAvailablePlace(dropResult, {
            start: new Date(order.departure_time),
            end: new Date(order.arriving_time),
          });

          const nbrPalaceRestante =
            paxNoAffected > availableInVehicle
              ? availableInVehicle
              : paxNoAffected;

          // const nbrPalaceRestante = getAvailablePlace(dropResult, {
          //   start: new Date(order.departure_time),
          //   end: new Date(order.arriving_time),
          // });

          if (availableInVehicle <= 0) {
            alert("Il ne reste pas de place disponible pour cette commande");
          } else if (paxNoAffected > availableInVehicle) {
            setState((state) => ({
              ...state,
              nbrPalaceRestante: nbrPalaceRestante,
              openDialog: true,
              targetVehicle: dropResult,
            }));
          } else {
            addPartialOrderToVehicle(dropResult, {
              arrivingTime: order.arriving_time,
              departureTime: order.departure_time,
              id: order.id,
              order_id: order.id,
              order_type: order.type,
              original: {
                reference: order.reference,
                id: order.id,
                pax: order.pax,
              },
              pax: {
                ...order.pax,
                total: paxNoAffected,
              },
            });
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [order, paxAffected]
  );

  const opacity = isDragging || paxNoAffected === 0 ? 0.4 : 1;
  const borderColor = isAllPaxAffected ? "secondary.main" : "#ddd";

  return (
    <Box
      ref={drag}
      sx={{
        borderLeft: `solid 5px`,
        borderColor: `${borderColor}`,
        pl: 1,
        opacity,
      }}
    >
      <Stack direction={"column"} spacing={1}>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          justifyContent="space-between"
        >
          <Typography>
            Ref : <b>{order.reference}</b>
          </Typography>
          <Typography>
            <b>{paxNoAffected}</b> passagers
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          justifyContent="space-between"
        >
          <Typography>
            <b>{order?.departure ?? ""}</b>
          </Typography>
          <Typography>
            <b>{order?.arriving ?? ""}</b>
          </Typography>
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={{ sm: 1, md: 2 }}
        >
          <Box sx={{ width: { sm: "100%", md: "50%" } }}>
            {/* <Typography noWrap>{order.departure.name}</Typography> */}
            <Typography color={"textSecondary"} sx={{ fontSize: 12 }}>
              {order.departure_time}
            </Typography>
          </Box>
          <Box sx={{ width: { sm: "100%", md: "50%" } }}>
            {/* <Typography align="right" noWrap>
              {order.arriving.name}
            </Typography> */}
            <Typography
              // align={"right"}
              noWrap
              color={"textSecondary"}
              sx={{ fontSize: 12, textAlign: { sx: "left", md: "right" } }}
            >
              {order.arriving_time}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={2}
          justifyContent="space-between"
        >
          <Typography>Pax :</Typography>
          <Box>
            {Object.keys(order.pax)
              .filter(
                (kp) =>
                  order.pax[kp as keyof typeof order.pax] > 0 && kp !== "total"
              )
              .map((kp, i) => (
                <Typography variant="caption" key={kp} sx={{ pl: 1 }}>
                  {kp} : <b>{order.pax[kp as keyof typeof order.pax]}</b>
                </Typography>
              ))}{" "}
          </Box>
        </Stack>
      </Stack>

      <Dialog
        open={state.openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Passagers autorisés "}
        </DialogTitle>
        <DialogContent>
          <OrderPaxForm
            nbrPalaceRestante={state.nbrPalaceRestante}
            setSelectedNbrPax={(pax) => {
              handleCloseDialog();
              addPartialOrderToVehicle(state.targetVehicle!, {
                id: order.id,
                order_id: order.id,
                order_type: order.type,
                original: {
                  reference: order.reference,
                  id: order.id,
                  pax: order.pax,
                },
                pax: {
                  ...order.pax,
                  total: pax,
                },
              });
            }}
            cancel={handleCloseDialog}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
